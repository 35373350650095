@font-face {
  font-family: 'syncopate-bold';
  src: url('./assets/fonts/syncopate-bold.ttf') format('ttf')
}

@font-face {
  font-family: 'intro-light';
  src: url('./assets/fonts/intro-light.otf') format('otf')
}

@font-face {
  font-family: 'intro-regular-italic';
  src: url('./assets/fonts/intro-regular-italic.otf') format('otf')
}

@font-face {
  font-family: 'intro-regular';
  src: url('./assets/fonts/intro-regular.otf') format('otf')
}

body {
  margin: 0;
  font-family:'syncopate-bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

