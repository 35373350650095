.Background {
    background-image: url('/src/assets/images/background-hot.png');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center;
}

.Logo {
    margin-top: 2%;
    height: 40vmin;
}

.Title {
    color: white;
    font-family: 'syncopate-bold', sans-serif;
    font-size: 3em;
}

.Connexion-btn {
    background-color: #095262;
    border-radius: 30px;
    border: none;
    text-transform: uppercase;
    width: 300px;
    height: 50px;
    color: white;
    font-family: 'syncopate-bold', sans-serif;
    font-size: 1.5em;
}